export const navFrontend = {
  navRod: "#c30000",
  navOransje: "#ff9100",
  navLimeGronn: "#a2ad00",
  navGronn: "#06893a",
  navLilla: "#634689",
  navDypBla: "#005b82",
  navBla: "#0067c5",
  navLysBla: "#66cbec",
  navMorkGra: "#3e3832",
  navGra80: "#59514b",
  navGra60: "#78706a",
  navGra40: "#b7b1a9",
  navGra20: "#c6c2bf",
  navLysGra: "#e9e7e7",
  fokusFarge: "#254b6d",
  orangeFocus: "#ffbd66",
  redError: "#ba3a26",
  white: "#fff",
  grayBackground: "#e9e7e7",
  grayIcon: "#b7b1a9",
  grayModia: "#333333",
  grayInactive: "#78706a",
  pinkErrorBg: "#f3e3e3",
  landingpageBla: "#004367",
  landingpageHeaderBla: "#004367",
  navGronnLighten20: "#38a161",
  navGronnLighten40: "#6ab889",
  navGronnLighten60: "#9bd0b0",
  navGronnLighten80: "#cde7d8",
  navGronnDarken20: "#117938",
  navGronnDarken40: "#1c6937",
  navGronnDarken60: "#285835",
  navGronnDarken80: "#334834",
  navLimeGronnLighten20: "#b5bd33",
  navLimeGronnLighten40: "#c7ce66",
  navLimeGronnLighten60: "#dade99",
  navLimeGronnLighten80: "#ecefcc",
  navLimeGronnDarken20: "#8e960a",
  navLimeGronnDarken40: "#7a7e14",
  navLimeGronnDarken60: "#66671e",
  navLimeGronnDarken80: "#524f28",
  navLysBlaLighten20: "#85d5f0",
  navLysBlaLighten40: "#a3e0f4",
  navLysBlaLighten60: "#c2eaf7",
  navLysBlaLighten80: "#e0f5fb",
  navLysBlaDarken20: "#5eaec7",
  navLysBlaDarken40: "#5690a2",
  navLysBlaDarken60: "#4e737c",
  navLysBlaDarken80: "#465557",
  navBlaLighten20: "#3385d1",
  navBlaLighten40: "#66a4dc",
  navBlaLighten60: "#99c2e8",
  navBlaLighten80: "#cce1f3",
  navBlaDarken20: "#0c5ea8",
  navBlaDarken40: "#19548a",
  navBlaDarken60: "#254b6d",
  navBlaDarken80: "#32414f",
  navDypBlaLighten20: "#337c9b",
  navDypBlaLighten40: "#669db4",
  navDypBlaLighten60: "#99bdcd",
  navDypBlaLighten80: "#ccdee6",
  navDypBlaDarken20: "#0c5472",
  navDypBlaDarken40: "#194d62",
  navDypBlaDarken60: "#254652",
  navDypBlaDarken80: "#323f42",
  navLillaLighten20: "#826ba1",
  navLillaLighten40: "#a190b8",
  navLillaLighten60: "#c1b5d0",
  navLillaLighten80: "#e0dae7",
  navLillaDarken20: "#5c4378",
  navLillaDarken40: "#544066",
  navLillaDarken60: "#4d3e55",
  navLillaDarken80: "#453b43",
  navRodLighten20: "#cf3333",
  navRodLighten40: "#db6666",
  navRodLighten60: "#e79999",
  navRodLighten80: "#f3cccc",
  navRodDarken20: "#a80b0a",
  navRodDarken40: "#8e1614",
  navRodDarken60: "#73221e",
  navRodDarken80: "#592d28",
  navOransjeLighten20: "#ffa733",
  navOransjeLighten40: "#ffbd66",
  navOransjeLighten60: "#ffd399",
  navOransjeLighten80: "#ffe9cc",
  navOransjeDarken20: "#d87f0a",
  navOransjeDarken40: "#b26d14",
  navOransjeDarken60: "#8b5c1e",
  navOransjeDarken80: "#654a28",
  orangeFocusLighten20: "#ffca85",
  orangeFocusLighten40: "#ffd7a3",
  orangeFocusLighten60: "#ffe5c2",
  orangeFocusLighten80: "#fff2e0",
  orangeFocusDarken20: "#d8a25c",
  orangeFocusDarken40: "#b28851",
  orangeFocusDarken60: "#8b6d47",
  orangeFocusDarken80: "#65533c",
  redErrorLighten20: "#c86151",
  redErrorLighten40: "#d6897d",
  redErrorLighten60: "#e3b0a8",
  redErrorLighten80: "#f1d8d4",
  redErrorDarken20: "#a13a28",
  redErrorDarken40: "#88392b",
  redErrorDarken60: "#70392d",
  redErrorDarken80: "#573830",
  surface: "#003553",
  screenSm: "768px",
  screenSmMin: "768px",
  screenTablet: "768px",
  screenMd: "992px",
  screenMdMin: "992px",
  screenDesktop: "992px",
  screenLg: "1200px",
  screenLgMin: "1200px",
  screenLgDesktop: "1200px",
  screenXsMax: "767px",
  screenSmMax: "991px",
  screenMdMax: "1199px",
  containerTablet: "736px",
  containerSm: "736px",
  containerDesktop: "956px",
  containerMd: "956px",
  containerLargeDesktop: "1156px",
  containerLg: "1156px",
  gridColumns: "12",
  gridGutterWidth: "16px",
  gridFloatBreakpoint: "768px",
  gridFloatBreakpointMax: "767px",
  paddingBaseVertical: "6px",
  paddingBaseHorizontal: "12px",
  paddingLargeVertical: "10px",
  paddingLargeHorizontal: "16px",
  paddingSmallVertical: "5px",
  paddingSmallHorizontal: "10px",
  paddingXsVertical: "1px",
  paddingXsHorizontal: "5px",
  lineHeightLarge: "1.3333333",
  lineHeightSmall: "1.5",
  borderRadiusSmall: "2px",
  borderRadiusBase: "4px",
  borderRadiusLarge: "6px",
};
