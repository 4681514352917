import * as React from "react";
import styled from "styled-components";

const Style = styled.div``;

function Spinner() {
  return <Style>Loading..</Style>;
}

export default Spinner;
