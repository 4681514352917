function NavLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 70 45" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 31.71l4.493-11.119H8.81l-4.487 11.12H0zM55.44 31.71l4.435-11.119h2.353l-4.435 11.12H55.44z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M64.161 31.71l4.435-11.119h1.25l-4.436 11.12h-1.249z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.608.13c12.142 0 21.984 9.843 21.984 21.983s-9.842 21.98-21.984 21.98c-12.137 0-21.978-9.84-21.978-21.98S20.47.13 32.608.13zM19.4 20.592h-3.475s-.268 0-.366.238l-1.42 3.52s-.142.353.182.353h1.336v6.71c0 .166.131.298.298.298H19.4a.298.298 0 00.298-.299v-6.71h1.343c.77 0 .933.022 1.233.161.18.069.343.207.432.366.182.341.227.752.227 1.963v4.22c0 .167.134.299.3.299h3.308a.585.585 0 00.514-.369l.732-1.808c.972 1.363 2.574 2.177 4.564 2.177h.442a.589.589 0 00.517-.369l1.274-3.156v3.226a.3.3 0 00.3.299h3.377a.587.587 0 00.514-.369s.326-.808.657-1.634l.069-.17.522-1.301.036-.09.07-.177h.001c.05-.265-.269-.278-.298-.278h-1.205v-5.743l3.785 9.393a.585.585 0 00.514.369h3.995a.587.587 0 00.516-.369l4.196-10.39c.14-.347-.245-.36-.274-.36H47.45s-.27 0-.364.238l-2.163 6.62-2.16-6.62c-.09-.226-.338-.238-.364-.238h-7.516a.302.302 0 00-.299.297v2.248c0-1.783-1.897-2.545-3.008-2.545-2.455 0-4.11 1.595-4.651 4.03l-.022.1-.002-.11c-.032-1.57-.174-2.149-.609-2.742-.204-.297-.5-.547-.822-.753-.663-.389-1.258-.525-2.537-.525h-1.501s-.272 0-.368.238l-1.366 3.386v-3.327a.3.3 0 00-.298-.297zm13.553 3.833c.9 0 1.631.734 1.631 1.635v1.632H32.32a1.633 1.633 0 110-3.267h.633z"
      />
    </svg>
  );
}

export default NavLogo;
